import React, { useEffect, useState, useCallback } from 'react';
import { PlainClientAPI } from 'contentful-management';
import { SidebarExtensionSDK } from '@contentful/app-sdk';
import {
  Paragraph,
  SkeletonContainer,
  SkeletonBodyText
} from '@contentful/f36-components';
import { client, gql } from '../services/graphql';

interface VariantSidebarProps {
  sdk: SidebarExtensionSDK;
  cma: PlainClientAPI;
}

enum Loading {
  Initialized = 0,
  Success = 1,
  Error = 2
}

interface Variant {
  [index: string]: unknown;
}

const VariantSidebar = ({ sdk }: VariantSidebarProps) => {
  const [sku, setSku] = useState<string>(sdk.entry.fields.sku.getValue());
  const [loadingState, setLoadingState] = useState<Loading>(
    Loading.Initialized
  );
  const [variant, setVariant] = useState<Variant>();

  sdk.entry.onSysChanged(
    useCallback(() => {
      const id = sdk.entry.fields.sku.getValue();
      if (id !== sku) {
        setSku(id);
      }
    }, [sdk, sku, setSku])
  );

  useEffect(() => {
    sdk.window.startAutoResizer();
    setLoadingState(Loading.Initialized);

    client
      .request(
        gql`
          query itemAsVariant($sku: String!) {
            itemAsVariant(sku: $sku)
          }
        `,
        { sku }
      )
      .then((res) => {
        const data = res?.itemAsVariant || {};
        setVariant(data);
        setLoadingState(Loading.Success);
        sdk.window.stopAutoResizer();
        sdk.window.updateHeight(500);
      })
      .catch((err) => {
        console.error(err);
        setLoadingState(Loading.Error);
      });
  }, [sku]);

  if (loadingState === Loading.Initialized) {
    return (
      <SkeletonContainer ariaLabel="Loading variant data">
        <SkeletonBodyText numberOfLines={4} />
      </SkeletonContainer>
    );
  } else if (loadingState === Loading.Error) {
    return (
      <SkeletonContainer backgroundColor="red" ariaLabel="ERROR LOADING">
        <SkeletonBodyText numberOfLines={4} />
      </SkeletonContainer>
    );
  }

  return (
    <Paragraph>
      <pre>{JSON.stringify(variant, null, 2)}</pre>
    </Paragraph>
  );
};

export default VariantSidebar;
