import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  ComponentPropsWithoutRef
} from 'react';
import { MultipleEntryReferenceEditor } from '@contentful/field-editor-reference';
import { SettingsIcon } from '@contentful/f36-icons';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import { Note } from '@contentful/f36-components';
import { EntryProps, PlainClientAPI, SysLink } from 'contentful-management';
import { debounce } from 'lodash';
import { Status, Item } from './Variant.sku';
import { patchEntryFieldReferences } from '../../utilities';
import {
  Autocomplete,
  Box,
  Flex,
  Card,
  Paragraph,
  Text,
  SkeletonContainer,
  SkeletonBodyText,
  Button,
  Stack,
  Checkbox,
  Asset,
  Menu,
  IconButton
} from '@contentful/f36-components';
import { client, gql } from '../services/graphql';
import { useDynamicHeight } from '../Field.hooks';

import './Product.variants.css';

const sillyQuotes = [
  'did you feed your cats today?',
  'when will we re-release leggings already???',
  'you look nice today!',
  'have a good day!',
  "we don't talk about Bruno, no, no, no"
];

interface ProductVariantsFieldProps {
  sdk: FieldExtensionSDK;
  cma: PlainClientAPI;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EntryPropsWithCustomMetaData = EntryProps & {
  custom: Record<string, any>;
};

enum BulkAction {
  Publish = 'Publish',
  Replicate = 'Replicate'
}

enum ReplicateAction {
  Swatch = 'Swatch',
  VariantImages = 'VariantImages'
}

interface ReplicateActionData {
  actions: Array<ReplicateAction>;
  fromId: string;
}

type CustomCardRendererProps = Parameters<
  NonNullable<
    ComponentPropsWithoutRef<
      typeof MultipleEntryReferenceEditor
    >['renderCustomCard']
  >
>;

/** Render the card */
const VariantCard = (
  props: CustomCardRendererProps[0],
  linkActionProps: CustomCardRendererProps[1],
  renderDefaultCard: CustomCardRendererProps[2],
  linkedEntries: undefined | Array<EntryPropsWithCustomMetaData>,
  sdk: FieldExtensionSDK,
  bulkEditIds: Array<string>,
  setBulkEditIds: (id: Array<string>) => void,
  runBulkAction: (
    action: BulkAction,
    data: ReplicateActionData | undefined
  ) => void,
  isBulkActionRunning: boolean
) => {
  const entity = props.entity;
  const id = entity.sys.id;
  const skuLocales = (entity.fields as Record<string, Record<string, string>>)
    ?.sku;
  const sku = (skuLocales && skuLocales[sdk.locales.default]) || 'this';

  let image = '';
  const entityWithCustomMetadata = linkedEntries?.find((e) => e?.sys.id === id);
  if (entityWithCustomMetadata) {
    image =
      entityWithCustomMetadata.custom?.swatchAsset?.fields?.file[
        sdk.locales.default
      ].url || image;
  }

  const hasImages =
    typeof entityWithCustomMetadata?.custom?.images !== 'undefined';

  return (
    <Stack style={{ gap: '0', position: 'relative', alignItems: 'stretch' }}>
      <Checkbox
        className="bombas-variant-checkbox"
        value={id}
        id={`option-${id}`}
        isChecked={bulkEditIds.includes(id)}
        onChange={(e) => {
          const target: HTMLInputElement = e.target as HTMLInputElement;
          if (target.checked === true) {
            setBulkEditIds([...bulkEditIds, id]);
          } else {
            setBulkEditIds(bulkEditIds.filter((i) => i !== id));
          }
        }}
      />
      <div className="bombas-variant-swatch">
        <Asset type="image" src={image} />
      </div>
      {renderDefaultCard(props)}
      <div className="bombas-variant-menu">
        <Menu>
          <Menu.Trigger>
            <IconButton
              isDisabled={isBulkActionRunning === true}
              variant="secondary"
              icon={<SettingsIcon />}
              aria-label="Toggle menu"
              size="small"
              style={{
                padding: '.25rem'
              }}
            />
          </Menu.Trigger>
          <Menu.List>
            <Menu.SectionTitle>Bulk Edit</Menu.SectionTitle>
            <Menu.Item
              disabled={image === ''}
              onClick={() =>
                runBulkAction(BulkAction.Replicate, {
                  actions: [ReplicateAction.Swatch],
                  fromId: id
                })
              }
            >
              Replicate Swatch
            </Menu.Item>
            <Menu.Item
              disabled={!hasImages}
              onClick={() =>
                runBulkAction(BulkAction.Replicate, {
                  actions: [ReplicateAction.VariantImages],
                  fromId: id
                })
              }
            >
              Replicate Images
            </Menu.Item>
            <Menu.Item
              disabled={image === '' || !hasImages}
              onClick={() =>
                runBulkAction(BulkAction.Replicate, {
                  actions: [
                    ReplicateAction.Swatch,
                    ReplicateAction.VariantImages
                  ],
                  fromId: id
                })
              }
            >
              Replicate Images &amp; Swatch
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item disabled>
              <Text fontColor="gray500" fontSize="fontSizeS">
                Data will be replicated to checked
                <br />
                variants from:
              </Text>
            </Menu.Item>
            <Menu.Item disabled>
              <Text fontColor="gray500" fontSize="fontSizeS">
                {sku}
              </Text>
            </Menu.Item>
          </Menu.List>
        </Menu>
      </div>
    </Stack>
  );
};

/** Field editor for Product.variants */
const ProductVariantsField = ({ sdk, cma }: ProductVariantsFieldProps) => {
  const baseRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const fieldRef = useRef<HTMLUListElement>(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState<Status>(Status.Idle);
  const [isAddingVariant, setIsAddingVariant] = useState<Status>(Status.Idle);
  const [isBulkActionRunning, setIsBulkActionRunning] = useState(false);
  const [items, setItems] = useState<Array<Item>>([]);
  const [linkedEntries, setLinkedEntries] =
    useState<undefined | Array<EntryPropsWithCustomMetaData>>(undefined);
  const [bulkEditIds, setBulkEditIds] = useState<Array<string>>([]);
  const [marketingData, setMarketingData] = useState({
    gender: sdk.entry.fields.marketed_gender.getValue(),
    age: sdk.entry.fields.marketed_age.getValue()
  });
  const hasMarketingData = !!marketingData.gender && !!marketingData.age;

  // Helper function to find all linked entries
  const populateEntries = useCallback(
    async (links: Array<SysLink> = []) => {
      const newLinkedEntries = (await Promise.all(
        links
          .map(
            async (link) =>
              await cma.entry
                .get({ entryId: link.sys.id })
                .then(async (e) => {
                  const entity = e as EntryPropsWithCustomMetaData;
                  entity.custom = {};

                  // Get information about the swatch if it exists. If it exists, we want
                  // to fetch the asset so we have the actual data related to it rather
                  // than just the ID.
                  const { swatch } = entity.fields as Record<
                    string,
                    | undefined
                    | Record<string, Record<string, Record<string, string>>>
                  >;
                  if (swatch) {
                    const localizedSwatch = swatch[sdk.locales.default];
                    const assetId = localizedSwatch?.sys?.id;
                    if (assetId) {
                      await new Promise((r) => setTimeout(r, 250)); // To avoid rate limiting
                      await cma.asset.get({ assetId }).then((asset) => {
                        entity.custom.swatchAsset = asset;
                      });
                    }
                  } else {
                    entity.custom.swatchAsset = undefined;
                  }

                  // Get information about the variant images if it exists. We don't need
                  // the data for each of the related assets here right now, so instead
                  // of fetching for each variant image (and related asset) for each
                  // variant, we are just storing the raw link data for our replication.
                  const { images } = entity.fields;
                  entity.custom.images = images;

                  return entity;
                })
                .catch(() => {
                  // Ignore missing/inaccessible linked entries
                  return null;
                })
          )
          .filter((x) => x)
      )) as Array<EntryPropsWithCustomMetaData>;

      setLinkedEntries(newLinkedEntries);

      return newLinkedEntries;
    },
    [cma, sdk]
  );

  // Populate the linked entries on change
  useEffect(() => {
    const unsubscribe = sdk.field.onValueChanged((links) => {
      populateEntries(links);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const replicate = useCallback(
    async (actions: Array<ReplicateAction>, fromId: string): Promise<void> => {
      // Lock the bulk editing menus until completed so we don't have multiple things going on at once
      setIsBulkActionRunning(true);
      console.info(
        `Starting replication actions (${JSON.stringify(
          actions
        )}) from "${fromId}" to ${JSON.stringify(bulkEditIds)}`
      );

      // Force update entries before doing anything else
      const newLinkedEntries = await populateEntries(sdk.field.getValue());

      try {
        const fromEntry = newLinkedEntries?.find(
          (entry) => entry.sys.id === fromId
        );
        if (!fromEntry) {
          throw new Error(`Invalid fromId (not found): "${fromId}"`);
        }

        /** Replicate swatches */
        const replicateSwatch = async () => {
          console.info('Beginning replication of Swatch');
          const { swatchAsset } = fromEntry.custom;
          if (!swatchAsset) {
            // This shouldn't happen, but just in case!
            throw new Error('No swatch to replicate from');
          }

          for (const entryId of bulkEditIds) {
            // Make sure we don't edit the same exact ID
            if (entryId === fromId) {
              continue;
            }

            console.info(`Replicating Swatch to ${entryId} ...`);
            await new Promise((r) => setTimeout(r, 100)); // To avoid rate limiting

            await patchEntryFieldReferences(
              cma,
              sdk,
              'swatch',
              {
                sys: {
                  type: 'Link',
                  linkType: 'Asset',
                  id: swatchAsset.sys.id
                }
              },
              entryId,
              sdk.locales.default
            );
          }
        };

        /** Replicate variant images */
        const replicateVariantImages = async () => {
          console.info('Beginning replication of VariantImages');
          const { images } = fromEntry.custom;
          if (!images) {
            // This shouldn't happen, but just in case!
            throw new Error('No variant images to replicate from');
          }

          for (const entryId of bulkEditIds) {
            // Make sure we don't edit the same exact ID
            if (entryId === fromId) {
              continue;
            }

            console.info(`Replicating VariantImages to ${entryId} ...`);
            await new Promise((r) => setTimeout(r, 100)); // To avoid rate limiting

            await patchEntryFieldReferences(
              cma,
              sdk,
              'images',
              images,
              entryId
            );
          }
        };

        for (const action of actions) {
          switch (action) {
            case ReplicateAction.Swatch:
              await replicateSwatch();
              break;
            case ReplicateAction.VariantImages:
              await replicateVariantImages();
              break;
            default:
              throw new Error(`Unsupported replication action: "${action}"`);
          }
        }

        await new Promise((r) => setTimeout(r, 500)); // To avoid rate limiting
        await populateEntries(sdk.field.getValue());
      } catch (e) {
        console.error('Error while replicating:', e);
      } finally {
        // When we are done, we set that our bulk actions are set to false regardless of the outcome
        setIsBulkActionRunning(false);
      }
    },
    [bulkEditIds, cma, sdk]
  );
  const bulkPublish = useCallback(async () => {
    setIsBulkActionRunning(true);

    try {
      for (const entryId of bulkEditIds) {
        const entry = linkedEntries?.find((e) => e.sys.id === entryId);
        if (!entry) {
          continue;
        }

        await cma.entry.publish({ entryId }, entry);
      }

      await populateEntries(sdk.field.getValue());
    } catch (e) {
      console.error('Error while publishing:', e);
    } finally {
      setIsBulkActionRunning(false);
    }
  }, [bulkEditIds, cma, populateEntries, linkedEntries, sdk]);
  const runBulkAction = useCallback(
    async (action: BulkAction, data?: ReplicateActionData) => {
      switch (action) {
        case BulkAction.Replicate:
          if (!data) {
            throw new Error('Missing data, can not replicate');
          }
          return await replicate(data.actions, data.fromId);
        case BulkAction.Publish:
          return await bulkPublish();
        default:
          throw new Error(`Unsupported action type: "${action}"`);
      }
    },
    [bulkPublish, replicate]
  );

  const debouncedQuery = useMemo(
    () =>
      debounce((value) => {
        client
          .request(
            gql`
              query itemSearchRaw($query: String!) {
                itemSearchRaw(query: $query)
              }
            `,
            { query: value }
          )
          .then((res) => {
            const newFilteredItems = (res?.itemSearchRaw || []).filter(
              (item: Item) => {
                const foundEntry =
                  linkedEntries &&
                  linkedEntries.find((entry) => {
                    if (entry?.fields?.sku) {
                      if (typeof entry?.fields?.sku === 'string') {
                        return entry.fields.sku === item.sku;
                      } else {
                        return (
                          entry.fields.sku[sdk.locales.default] === item.sku
                        );
                      }
                    }

                    return false;
                  });

                return typeof foundEntry === 'undefined';
              }
            );
            setItems(newFilteredItems);
            setIsLoadingSearch(Status.Success);
          })
          .catch((err) => {
            console.error(err);
            setIsLoadingSearch(Status.Failure);
          });
      }, 300),
    [sdk.locales.default, linkedEntries]
  );
  const handleInputValueChange = (value: string | Item) => {
    if (value !== '') {
      setIsLoadingSearch(Status.Loading);
      debouncedQuery(value);
    }
  };
  const handleSelectItem = async (selectedItem: Item) => {
    setIsAddingVariant(Status.Loading);

    // Remove selected item from list
    setItems((prevItems) =>
      prevItems.filter((item: Item) => item.sku !== selectedItem.sku)
    );

    // Check to see if the variant exists before creating
    const possibleEntries = await cma.entry.getMany({
      query: {
        content_type: 'variant',
        'fields.sku': selectedItem.sku,
        'fields.marketed_gender': marketingData.gender,
        'fields.marketed_age': marketingData.age
      }
    });

    // If it exists, grab the first instance of it. If not, create a new one.
    const entry =
      possibleEntries?.total > 0
        ? possibleEntries.items[0]
        : await cma.entry.create(
            {
              contentTypeId: 'variant'
            },
            {
              fields: {
                sku: {
                  [sdk.locales.default]: selectedItem.sku
                },
                marketed_gender: {
                  [sdk.locales.default]: marketingData.gender
                },
                marketed_age: {
                  [sdk.locales.default]: marketingData.age
                }
              }
            }
          );

    // Publish the variant
    await cma.entry.publish({ entryId: entry.sys.id }, entry);

    const existingFields = sdk.field.getValue() || [];
    // Merge the existing variants with the newly added variant
    sdk.field.setValue([
      ...existingFields,
      {
        sys: {
          type: 'Link',
          linkType: 'Entry',
          id: entry.sys.id
        }
      }
    ]);

    setIsAddingVariant(Status.Success);
  };

  // Listen for changes to `marketed-gender/age` field.
  useEffect(() => {
    const handleChange = (key: string) => (value: string) => {
      setMarketingData((prev) => ({ ...prev, [key]: value }));
    };
    const unsubscribeGender = sdk.entry.fields.marketed_gender.onValueChanged(
      handleChange('gender')
    );
    const unsubscribeAge = sdk.entry.fields.marketed_age.onValueChanged(
      handleChange('age')
    );

    return () => {
      unsubscribeGender();
      unsubscribeAge();
    };
  }, [sdk.entry.fields.marketed_gender, sdk.entry.fields.marketed_age]);

  useDynamicHeight(sdk, { baseRef, fieldRef });

  return (
    <div ref={baseRef}>
      {isBulkActionRunning && (
        <div className="bombas-loading-overlay">
          Working...
          <span>
            {sillyQuotes[Math.floor(Math.random() * sillyQuotes.length)]}
          </span>
        </div>
      )}
      <Stack flexDirection="column" fullWidth={true}>
        {!hasMarketingData && (
          <Note
            variant="warning"
            style={{ width: '100%' }}
            title="Missing Marketing Gender / Age"
          >
            Set a marketing gender and age to add variants.
          </Note>
        )}
        <Card>
          <Paragraph>
            <Text>
              Search for variants to add to the product{' '}
              {hasMarketingData && (
                <>
                  (Gender:
                  <b> {marketingData.gender}</b>, Age:
                  <b> {marketingData.age}</b>)
                </>
              )}
            </Text>
          </Paragraph>
          <Autocomplete
            placeholder="SKU, Style Number, Name, or Color"
            listMaxHeight={300}
            listRef={fieldRef}
            inputRef={inputRef}
            onInputValueChange={handleInputValueChange}
            onSelectItem={handleSelectItem}
            isLoading={isLoadingSearch === Status.Loading}
            isDisabled={!hasMarketingData}
            listWidth="full"
            clearAfterSelect={false}
            closeAfterSelect={false}
            noMatchesMessage="No items found, try being more specific."
            items={items}
            itemToString={() => inputRef.current?.value || ''} // Preserve the search term after an item is selected so additional items can be added.
            renderItem={(item: Item) => (
              <Flex flexDirection="column">
                <Box>
                  <Text fontWeight="fontWeightMedium">
                    SKU: {item?.sku} - {item?.Sku?.Style?.name}{' '}
                  </Text>
                  <Text>
                    (
                    {item?.InventoryItems?.find((x) => x.Store.region === 'US')
                      ?.available || '0'}
                    )
                  </Text>
                </Box>
                <Stack
                  flexDirection="column"
                  flexWrap="wrap"
                  spacing="none"
                  alignItems="left"
                >
                  <Box>
                    <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
                      Marketing Name: {item?.Sku?.Style?.site_name}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
                      Style: {item?.Sku?.Style?.StyleTag?.node_name} -{' '}
                      {item?.Sku?.Style?.style_number}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
                      Color:{' '}
                      {item?.Sku?.Colorway?.site_color || 'Missing from PLM'}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize="fontSizeS" lineHeight="lineHeightCondensed">
                      Replenishment: {item?.Sku?.Colorway?.core_fashion}
                    </Text>
                  </Box>
                </Stack>
              </Flex>
            )}
          />
        </Card>
        {!linkedEntries ||
          (isAddingVariant === Status.Loading && (
            <Card>
              <SkeletonContainer
                svgHeight="46px"
                ariaLabel="Loading variant(s)"
              >
                <SkeletonBodyText numberOfLines={3} />
              </SkeletonContainer>
            </Card>
          ))}
        {isAddingVariant === Status.Failure && (
          <Text fontColor="red500">No results found.</Text>
        )}
        {linkedEntries !== undefined && (
          <div style={{ width: '100%' }}>
            <MultipleEntryReferenceEditor
              viewType="link"
              sdk={sdk}
              hasCardEditActions
              isInitiallyDisabled={false}
              parameters={{
                instance: {
                  showCreateEntityAction: true,
                  showLinkEntityAction: false
                }
              }}
              renderCustomCard={(...props) =>
                VariantCard(
                  ...props,
                  linkedEntries,
                  sdk,
                  bulkEditIds,
                  setBulkEditIds,
                  runBulkAction,
                  isBulkActionRunning
                )
              }
              // We need to force re-render because the custom card function is wrapped in useMemo
              // and it's the only way for us to pass updated state down to it.
              // https://github.com/contentful/field-editors/blob/%40contentful/field-editor-reference%405.3.2/packages/reference/src/entries/WrappedEntryCard/FetchingWrappedEntryCard.tsx#L108
              key={Math.random()}
            />
            <Button
              size="small"
              style={{ marginTop: '20px', marginRight: '5px' }}
              onClick={() => setBulkEditIds([])}
            >
              Clear
            </Button>
            <Button
              size="small"
              style={{ marginTop: '20px', marginRight: '5px' }}
              onClick={() =>
                setBulkEditIds(linkedEntries.map((e) => e?.sys.id))
              }
            >
              Select All
            </Button>
            <Button
              size="small"
              style={{ marginTop: '20px', marginRight: '5px' }}
              onClick={() => window.location.reload()}
            >
              Force Refresh
            </Button>
            <Button
              size="small"
              style={{ marginTop: '20px', marginRight: '5px' }}
              onClick={() => runBulkAction(BulkAction.Publish)}
            >
              Publish Variants
            </Button>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default ProductVariantsField;
