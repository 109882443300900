import { useState, useEffect, useRef, RefObject } from 'react';
import { FieldExtensionSDK } from '@contentful/app-sdk';
import isEqual from 'lodash.isequal';
import { SysLink } from 'contentful-management';

type ChangeEventValue = Array<SysLink> | undefined;

/**
 * React hook for detecting field change events in Contenful
 * Events are often duplicated so this ensures one call per valid data change event.
 */
export const useFieldChangeHook = (
  sdk: FieldExtensionSDK,
  callback: (next: ChangeEventValue) => void
) => {
  const previous = useRef(sdk.field.getValue());

  useEffect(() => {
    const unsubscribe: () => void = sdk.field.onValueChanged(
      (next: ChangeEventValue = []) => {
        // If field is an array, Contentful updates the field twice- first with the item removed, second to undefined.
        // When this is the case, it would perform two value changes where we can discard the second.
        if (
          (Array.isArray(previous.current) &&
            !previous.current.length &&
            next === undefined) ||
          (Array.isArray(next) &&
            !next.length &&
            previous.current === undefined)
        )
          return;

        const hasChange = !isEqual(previous.current, next);
        previous.current = next;

        if (hasChange) callback(next);
      }
    );

    return () => unsubscribe();
  }, [sdk.field, callback]);
};

/**
 * An alternative to Contentful's resizer, designed to work with Autocomplete component results.
 */
export const useDynamicHeight = (
  sdk: FieldExtensionSDK,
  opts: { baseRef: RefObject<any>; fieldRef: RefObject<any> }
) => {
  const baseHeight = useRef(100);
  const [elements, setElements] = useState({
    baseElement: opts.baseRef.current,
    fieldElement: opts.fieldRef.current
  });

  // Creates a cache of resolved refs since they can be null, thereby creating a
  // mechanism to trigger re-render without requiring any fancy workarounds on the implementers behalf.
  if (
    opts.baseRef.current !== elements.baseElement ||
    opts.fieldRef.current !== elements.fieldElement
  ) {
    setElements({
      baseElement: opts.baseRef.current,
      fieldElement: opts.fieldRef.current
    });
  }

  useEffect(() => {
    baseHeight.current =
      elements.baseElement?.scrollHeight || document.body.scrollHeight;
    sdk.window.updateHeight(baseHeight.current);

    if (!elements.baseElement && !elements.fieldElement) return;

    const autoResizer = new ResizeObserver(([entry]) => {
      if (!entry) return;
      sdk.window.updateHeight(elements.baseElement.scrollHeight + 8);
    });

    if (elements.fieldElement) {
      autoResizer.observe(elements.fieldElement);
    }
    if (elements.baseElement) {
      autoResizer.observe(elements.baseElement);
    }

    return () => {
      autoResizer.disconnect();
    };
  }, [sdk.window, elements]);
};
